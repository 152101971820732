@import '~@angular/material/theming';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

@include mat-core();

@import './styles-variables.scss';


$mat-primary: (
  50: $mainColor,
  100: $mainColor,
  200: $mainColor,
  300: $mainColor,
  400: $mainColor,
  500: $mainColor,
  600: $mainColor,
  700: $mainColor,
  800: $mainColor,
  900: $mainColor,
  A100: $mainLightColor,// Lighter
  A200: $mainColor,
  A400: $mainDarkColor, // Darker
  A700: $mainColor,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: $white-87-opacity,
  )
);

$mat-accent: (
  50: $accentColor,
  100: $accentColor,
  200: $accentColor,
  300: $accentColor,
  400: $accentColor,
  500: $accentColor,
  600: $accentColor,
  700: $accentColor,
  800: $accentColor,
  900: $accentColor,
  A100: $accentLightColor, // Lighter
  A200: $accentColor,
  A400: $accentDarkColor, // Darker
  A700: $accentColor,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: black,
  )
);

$app-primary: mat-palette($mat-primary, A200, A100, A400);
$app-accent:  mat-palette($mat-accent, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn:    mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);


/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: 'Poppins', "Helvetica Neue", sans-serif !important; }

span {
  font-family: 'Poppins', sans-serif;
}

.mat-drawer-container {
  height: 100vh;
}

#global-container {
  // background-color: #F0F0F0;
  width: 100%;
  // height: 90vh;
  overflow-x: hidden;

  .main-container {
    width: $screen-max-Width;    
    padding-bottom: 60px;
    // height: 100%;
  }
}

.container {
  // width: 100%;
  // width: 372px;
  // min-height: 900px;  
  position: relative;  
  width: 100%;
  // height: 89vh;  
  // height: 100%;
}

.post-list {
  width: 100%;
}


.white-card{
  background-color: white;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  border-radius: 2px;
}

// .mat-checkbox-layout{
//   white-space: normal !important;
  
//   }
//   .mat-checkbox-inner-container{
//       margin-top: 4px;
//   }

.link {
  color: $blue300;
}

.pointer {
  cursor: pointer;
}

.overlay {
  position: fixed;
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: -2;
  cursor: pointer;
}

// FIXME: Si se necesita cambiar, este se encuentra en el `styles.scss`
.coupon-dialog-container .mat-dialog-container {
  // border-radius: 100px !important;
  border-radius: 0;
  padding: 0;
  background: unset;
  box-shadow: unset;
  width: 95vw;
  max-height: 80vh;
  // box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.profile-dialog-container .mat-dialog-container {
  background: unset;
  box-shadow: unset;
}
