$mainColor: #81ccf8;
$mainLightColor: #a7deff;
$mainDarkColor:#2a8fce;
$accentColor: #f9ae00;
$accentLightColor: #faf6e5;
$accentDarkColor: #fdd466;

// Colors
$grey100: #FAFAFA;
$grey200: #F0F0F0;
$grey400: #D0D0D0;
$grey500: #A5A5A5;
$grey600: #898A8D;
$grey800: #333333;
$blue300: #7FC5EF;
$blue400: #81CCF8;
$blue600: #2B507B;
$blue700: #0E2B4E;
$blue800: #011E41;
$yellow900: #F9AE00;

// Fonts
$fontDefaultSizeDesktop: 11px;
$fontDefaultSize: 0.9375rem;
$fontDefaulLineHeight: 1.66;
$fontInputSize: 16px;
$regular-font: 400;
$semibold-font: 600;
$bold-font: 700;

// Size
$screen-max-Width: 680px;

//Hover Animations
$hoverTransitionTime: 400ms;
$hoverBackgroundColor: $blue300;
$hoverBackgroundColorAlt: $grey400;